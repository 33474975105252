import React from "react";
import Head from "next/head";

type NextHeadProps = {
  title: string;
  canonicalUrl: string;
};

function NextHead({ title, canonicalUrl }: NextHeadProps) {
  return (
    <Head>
      <title>{title}</title>
      <meta
        name={"description"}
        content={
          "Entdecken Sie die zuverlässigen Reinigungsdienste von Musliu Reinigung in Hamburg. Wir bieten professionelle Reinigungslösungen in Hamburg - Tel:04051907299"
        }
      />
      <meta
        name="keywords"
        content="Musliu Reinigung, Hamburg, Professionele Reinigung, Büroreinigung, Praxisreinigung, Grundreinigung, Treppenhausreinigung"
      />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content="Zuverlässige Reinigunsdienste - Musliu Reinigung Hamburg"
      />
      <meta property="og:image" content="/bannerOgImage.jpg" />
      <meta name="twitter:image" content="/bannerOgImageTwitter.jpg" />
      <meta name="twitter:image:type" content="image/jpeg" />
      <meta name="twitter:image:width" content="1200" />
      <meta name="twitter:image:height" content="600" />
      <meta name="HandheldFriendly" content="true" />
      <link rel="icon" href="/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="canonical" href={canonicalUrl} />
      <link rel="manifest" href="/manifest.webmanifest" />
    </Head>
  );
}

export default NextHead;

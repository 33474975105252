import type { NextPage, GetServerSideProps } from "next";

import dynamic from "next/dynamic";

import React from "react";

import styles from "../styles/Home.module.scss";
import NextHead from "../components/NextHead";
import { getServerSidePropsWithCanonical } from "../helperFunctions/getServersidePropsWithCanonical";

const Intro = dynamic(() => import("../components/intro/intro"));
const AboutUs = dynamic(() => import("../components/aboutUs/aboutUs"));
const Services = dynamic(() => import("../components/services/services"));
const Contact = dynamic(() => import("../components/contact/contact"));
const Impressum = dynamic(() => import("../components/impressum/impressum"));

type HomeProps = {
  fullUrl: string;
};

const Home: NextPage<HomeProps> = ({ fullUrl }) => {
  return (
    <div className={styles["home-container"]}>
      <NextHead
        title="Musliu Reinigung Hamburg – Professionelle Reinigungsdienste"
        canonicalUrl={fullUrl}
      />
      <Intro />
      <Services />
      <Contact />
      <AboutUs />
      <Impressum />
    </div>
  );
};

export const getServerSideProps: GetServerSideProps<HomeProps> =
  getServerSidePropsWithCanonical;

export default Home;
